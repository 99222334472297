#schedule.schedule-area {
    h2,
    h3 {
        color: #cb9339 !important;
    }
    h3 {
        font-size: 23px;
    }
    h5{
      font-size: 18px;
      color:#ccc
    }
    .tabs {
        li {
            background-color: #cb9339;
            div {
                color: white !important;
            }
            &.current {
                &,
                &::before {
                    background-color: #0d3d66;
                }
            }
        }
    }
    .tab_content {
        background-color: white !important;
    }

    .schedule-info {
        padding-left: 150px;
        position: relative;
        
        & > .init-time {
            position: absolute;
            left: 0;
            top: 0;
            font-weight: 600;
            font-size: 24px;
            color: #212529;
        }
        h4 {
            display: block;
            color: #0d1028 !important;
        }
        .avatar {
            display: block;
             width: 110px;
             height: 110px;
             margin-right: 10px; 
             border-radius: 50%;
             img {
                 width: 100%;
                 width: 150px;
             }
        }
        .presenter-info {
            display: flex;
            .avatar {
                width: 110px;
                height: 110px;
                overflow: hidden;
                border-radius: 50%;
            }
            div {
                flex: 1;
            }
        }
    }
    .accordion-item:not(:last-child) {
        padding-bottom: 25px;
        border-bottom: 1px #ccc solid;
    }

    @media screen and (max-width: 991px) {
        .presenter-info {
            flex-direction: column;
            align-items: center;
            .avatar {
                margin-bottom: 20px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .init-time {
            position: inherit !important;
        }
        .schedule-info {
            padding-left: 0;
        }
    }
}
